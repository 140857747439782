<template>
  <button @click="onClick" :class="colorClass + ' ' + marginZero">{{name}}</button>
</template>

<script>
export default {
    props: ['name', 'type', 'action', 'noMargin', 'toggledButton', 'active'],
    data() {
        return {
            value: "",
            colorClass: "fill1",
            marginZero: ""
        }
    },
    created() {
       if(this.type == "fill"){
           if(this.active) {
            this.colorClass = "fill2" 
           } else {
               this.colorClass = "fill1"
           }
       } else {
           this.colorClass = "stroke"
       }
       if(this.noMargin) {
           this.marginZero = 'marginZero'
       }
    },
    watch: {
        active(){
            if(this.active) {
            this.colorClass = "fill2" 
           } else {
               this.colorClass = "fill1"
           }
        }
    },
    methods: {
        onClick(val = null){
            this.$emit(this.action, val)
            if(this.toggledButton) {
                if(this.type == "fill"){
                    if(this.colorClass == "fill1"){
                        this.colorClass = "fill2"
                    } else {
                        this.colorClass = "fill1"
                    }
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>

    button {
        margin-top: 35px;
        min-width: 100px;
        padding-left: 30px;
        padding-right: 30px;
        height: 40px;
        border-radius: 20px;
        font-weight: bold;
        font-size: 15px;
    }
    
    .marginZero {
        margin-top: 0 !important;
        font-size: 12px;
        margin-left: 5px;
    }

    @media only screen and (max-width: 1025px) {
        .marginZero {
            font-size: .7rem !important;
            margin-left: 5px !important;
        }

        button {
            min-width: 80px !important;
            font-size: 0.7rem !important;
        }
    }    

    .fill1 {
        background-color: #E2F5A1;
        color: #282828;
        border: none;
    }
    .fill2 {
        background-color: #282828;
        color: #FFFFFF;
        border: none;
    }
    .fillBlack {
       background-color: #282828;
        color: #FFFFFF;
        border: none;
    }
    .stroke {
        background-color: transparent;
        color: #282828;
        border: 2px solid #E2F5A1;  
    }
    .fill1:hover {
        background-color: #a6ab4e;
        color: #FFFFFF;
    }
    .stroke:hover {
        border: 2px solid #282828;  
    }
    @media only screen and (min-width: 1026px) and (max-width: 1025px) {
        .fill1{
            margin-top: 1rem;
            min-width: auto;
            padding-left: 15px;
            padding-right: 15px;
            height: 2rem;
            border-radius: 20px;
            font-weight: bold;
            font-size: .7rem;
        }
        .fill2{
            margin-top: 1rem;
            min-width: auto;
            padding-left: 15px;
            padding-right: 15px;
            height: 2rem;
            border-radius: 20px;
            font-weight: bold;
            font-size: .7rem;
        }
    }
</style>